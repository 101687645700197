import { Button, LinearProgress } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { routeCodes } from "./Routes";
import { parseJwt } from "../utils";

function getTimestampInSeconds () {
    return Math.floor(Date.now() / 1000)
}

export const LogoutButton = (props) => {
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useLocalStorage("token", undefined);
    const [percenteLeft, setPercentLeft] = useState({num:0});
    const navigate = useNavigate();

    function Logout(){
        setToken('');
        console.log(routeCodes.BASE);
        navigate(routeCodes.BASE);   
        setLoading(false);   
    }

    useEffect(() => {
            const timer = setTimeout(() => { 
                if (!parseJwt(token)){
                    Logout(); 
                    return;
                }
                var dt1 = parseJwt(token).exp;
                var dt1at = parseJwt(token).iat;
                var dt2 = getTimestampInSeconds();

                var pct = (((dt1at-dt2)/(dt1-dt2))*100) * -1;
                setPercentLeft({num:Number(pct)}) 

                if(pct>=100){
                    Logout(); 
                }

            }, 1000);
            return () => clearTimeout(timer);
    }, [percenteLeft]);

    const handleButtonClick = () => Logout();

    return <div style={props.style}><Box sx={{ display: 'flex', alignItems: 'center' }} ><Box sx={{ m: 1, position: 'relative' }}>
        <Button
            variant="contained"
            style={{borderBottomLeftRadius:'0px',borderBottomRightRadius:'0px'}}
            disabled={!loading}
            onClick={handleButtonClick}
        >
            <MeetingRoomIcon/> Logout
            
        </Button>
        {loading && (
            <LinearProgress
                variant="determinate"
                value={percenteLeft.num}
                color='warning'
                
            />
        )}
    </Box>
    </Box>
    </div>
}