import { useLocalStorage } from "../hooks/UseLocalStorage";
import { APICodes } from "./API";

export const Logo = (props) => {
    const [d_azienda, setdAzienda] = useLocalStorage("d_azienda", undefined);
    const [d_unita, setdUnita] = useLocalStorage("d_unita", undefined);
    const [uid_cliente, setuid_cliente] = useLocalStorage("uid_cliente", undefined);
    
    function DescrizioneAzienda() {
        if((d_azienda!=undefined)&&(d_azienda!=='')){
            return <p style={{marginTop:"-5px"}}>{d_azienda} <small>({d_unita})</small></p>
        }else{
            return <p style={{marginTop:"-5px"}}>Azienda e unità locale non configurata</p>
        }
    }
    function LogoAzienda() {
        let url = '';
        let style = {
            width: '200px',
            height: '100px',
            objectFit: 'contain',
            marginTop:"-25px"
        }
        if (uid_cliente!=undefined){
            url = APICodes.INFO_CLIENTE+uid_cliente+'/logo';
        }
        return <img style={style} src={url} onError={(e)=>{e.target.style.display="none"}}/>
    }
    return <>
    <div style={props.style}>
    <h1>KD Software</h1>
    <h2 style={{marginTop:"-35px"}}>Gestione Presenze <span style={{fontSize:'8px',verticalAlign:'top'}}>{process.env.REACT_APP_VERSION}</span></h2>
    {LogoAzienda()}
    {DescrizioneAzienda()}
    </div>
    </>
}