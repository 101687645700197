import { Alert, Box, Button, Fade, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { routeCodes } from "../components/Routes";
import { APICodes } from "../components/API";
import QrReader from "react-qr-reader";

const RootStyle = styled("div")({
    /*   background: "rgb(249, 250, 251)",*/
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const HeadingStyle = styled(Box)({
    textAlign: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    /*  background: "#fff",*/
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.6, ease: easing },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: easing,
        },
    },
};

const logoutBtnStyle = {
    position: 'absolute',
    top: 16,
    right: 16,
};

export const SettingsPage = () => {
    const [token, setToken] = useLocalStorage("token", undefined);

    const [uid_cliente, setUidCliente] = useLocalStorage("uid_cliente", undefined);
    const [id_azienda, setIdAzienda] = useLocalStorage("id_azienda", undefined);
    const [id_unita, setIdUnita] = useLocalStorage("id_unita", undefined);
    const [d_azienda, setdAzienda] = useLocalStorage("d_azienda", undefined);
    const [d_unita, setdUnita] = useLocalStorage("d_unita", undefined);

    const [_uid_cliente, set_UidCliente] = useState("");
    const [_id_azienda, set_IdAzienda] = useState("");
    const [_id_unita, set_IdUnita] = useState("");
    const [_d_azienda, set_dAzienda] = useState("");
    const [_d_unita, set_dUnita] = useState("");


    const [reason, setReason] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [sendDisabled, setSendDisabled] = useState(false);
    const navigate = useNavigate();

    const [selected, setSelected] = useState("user");
    const [startScan, setStartScan] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState("");

    const handleScan = async (scanData) => {
        setLoadingScan(true);
        console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            console.log(`loaded >>>`, scanData);
            setData(scanData);
            setStartScan(false);
            setLoadingScan(false);
            ElabQRCode(scanData);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    const ElabQRCode = (qrdata) => {         
        if(qrdata==""){ return false;}
        try {
            let J = JSON.parse(qrdata);
            if(("uid_cliente" in J)&&("id_azienda" in J)&&("id_unita" in J)){
                set_UidCliente(J.uid_cliente);
                set_IdAzienda(J.id_azienda);
                set_IdUnita(J.id_unita);    
            }else{
                console.error(J);
                setReason("Il QR code letto non è valido, informazioni non valide!");
            }
        } catch (e) {
            console.error(e);
            setReason("Il QR code letto non è valido, formato dati non corretto!");
            setData("");
            return false;
        }  
    }

    useEffect(() => {
        set_UidCliente(uid_cliente);
        set_IdAzienda(id_azienda);
        set_IdUnita(id_unita);
        set_dAzienda(d_azienda);
        set_dUnita(d_unita);
    }, []);

    function Logout() {
        //navigate(routeCodes.BASE);
        window.location.reload(false);
        setLoading(false);
    }

    const handleInvia = () => {
        setReason('');
        setSuccess('');

        let body = {
            "id_azienda": _id_azienda,
            "id_unita": _id_unita,
            "uid_cliente": _uid_cliente
        }

        const url = APICodes.INFO_UNITA;
        setLoading(true);
        fetch(url, {
            method: "POST",
            headers: {
                authorization: 'bearer ' + token
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                //if (response.status == 201) {
                //    return response.json();
                //} else {
                return response.json();
                //}
            })
            .then((data) => {
                setLoading(false);
                // code here //
                if (data.message != undefined) {
                    setReason(data.message);
                } else {
                    console.debug(data);
                    setUidCliente(_uid_cliente);
                    setIdAzienda(_id_azienda);
                    setIdUnita(_id_unita);
                    setdAzienda(data.unita_locale.azienda);
                    set_dAzienda(data.unita_locale.azienda);
                    setdUnita(data.unita_locale.unita);
                    set_dUnita(data.unita_locale.unita);
                    setSuccess('Configurazione salvata!');
                    setTimeout(() => {
                        Logout();
                    }, 3000);
                }
                setLoading(false);
            })
            .catch((err) => {
                setReason("Errore di connessione al server, riprovare tra poco o contattare l'assistenza. (" + err + ")");
                setLoading(false);
            });
    };

    return <>
        <RootStyle>
            <Container maxWidth="sm">
                <ContentStyle>
                    <HeadingStyle component="div" {...fadeInUp}>
                        <Typography sx={{ color: "text.secondary", mb: 5 }}>
                            Configurazione
                        </Typography>
                    </HeadingStyle>
                    <Stack
                        component="form"
                        sx={{

                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                    >

                        <div>
                            <Button
                                startIcon={<QrCodeScannerIcon />}
                                onClick={() => {
                                    setStartScan(!startScan);
                                }}
                            >
                                {startScan ? "Stop Scan" : "Start Scan"}
                            </Button>
                            {startScan && (
                                <>
                                    <Select  size="small" onChange={(e) => setSelected(e.target.value)} value={selected} style={{float:"right"}}>
                                        <MenuItem  value={"environment"}>Back Camera</MenuItem >
                                        <MenuItem  value={"user"}>Front Camera</MenuItem >
                                    </Select>
                                    <QrReader
                                        facingMode={selected}
                                        delay={1000}
                                        onError={handleError}
                                        onScan={handleScan}
                                        // chooseDeviceId={()=>selected}
                                        style={{ width: "auto" }}
                                    />
                                </>
                            )}
                            {/*data !== "" && <p>{data}</p>*/}
                        </div>
                        <TextField
                            color="warning"
                            label="Identificativo Cliente"
                            onChange={(e) => { set_UidCliente(e.target.value.substring(0, 36)) }}
                            value={_uid_cliente}
                        />
                        <TextField
                            color="warning"
                            label="Identificativo Azienda"
                            type="number"
                            pattern="[0-9]*"
                            onChange={(e) => { set_IdAzienda(e.target.value) }}
                            value={_id_azienda}
                            helperText={d_azienda}
                        />
                        <TextField
                            color="warning"
                            label="Identificativo Unità Locale"
                            type="number"
                            pattern="[0-9]*"
                            onChange={(e) => { set_IdUnita(e.target.value) }}
                            value={_id_unita}
                            helperText={d_unita}
                        />
                        {reason != '' ? <Alert severity='error'>{reason}</Alert> : <></>}
                        {success != '' ? <Alert severity='success'>{success}</Alert> : <></>}
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<ArrowBackIosIcon />} disabled={loading} style={{ width: "40%" }} color="secondary" onClick={() => { navigate(routeCodes.HOME) }}>
                                Esci
                            </Button>
                            <Button variant="contained" startIcon={<SaveIcon />} disabled={loading || (_uid_cliente == undefined) || ((_uid_cliente != undefined) && (_uid_cliente.trim().length < 35))} style={{ width: "60%" }} onClick={handleInvia} >
                                Salva configurazione
                            </Button>
                        </Stack>
                        <Fade
                            in={loading}
                            unmountOnExit
                        >
                            <LinearProgress color="secondary" />
                        </Fade>


                    </Stack>
                </ContentStyle>
            </Container>
        </RootStyle></>
}