import { Alert, Box, Button, Fade, LinearProgress, TextField, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../components/LogoutButton";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { routeCodes } from "../components/Routes";
import { APICodes } from "../components/API";

const RootStyle = styled("div")({
    /*   background: "rgb(249, 250, 251)",*/
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const HeadingStyle = styled(Box)({
    textAlign: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    /*  background: "#fff",*/
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.6, ease: easing },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: easing,
        },
    },
};

const logoutBtnStyle = {
    position: 'absolute',
    bottom: 16,
    left: 16,
};

export const PaginaMessaggio = () => {
    const [testo, setTesto] = useState('');
    const [reason, setReason] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [sendDisabled, setSendDisabled] = useState(false);
    const navigate = useNavigate();
    const [id_azienda, setIdAzienda] = useLocalStorage("id_azienda", undefined);
    const [id_unita, setIdUnita] = useLocalStorage("id_unita", undefined);
    const [token, setToken] = useLocalStorage("token", undefined);

    function Logout() {
        setToken('');
        navigate(routeCodes.BASE);
        setLoading(false);
    }

    const handleInvia = () => {

        let body = {
                "messaggio": {
                    "id_azienda": id_azienda,
                    "id_unita": id_unita,
                    "testo": testo
                }
            }

        const url = APICodes.MESSAGGI;
        setLoading(true);
        fetch(url, {
            method: "POST",
            headers: {
                authorization: 'bearer ' + token
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                //if (response.status == 201) {
                //    return response.json();
                //} else {
                return response.json();
                //}
            })
            .then((data) => {
                setLoading(false);
                // code here //
                if (data.message != undefined) {
                    setReason(data.message);
                } else {
                    setSuccess('Messaggio inviato con successo!');
                    setTimeout(() => {
                        Logout();
                    }, 3000);
                }
                setLoading(false);
            })
            .catch((err) => {
                setReason("Errore di connessione al server, riprovare tra poco o contattare l'assistenza. (" + err + ")");
                setLoading(false);
            });
    };

    return <>           
        <RootStyle>
            <LogoutButton style={logoutBtnStyle} />
            <Container maxWidth="sm">
                <ContentStyle>
                    <HeadingStyle component="div" {...fadeInUp}>                      
                        <Typography sx={{ color: "text.secondary", mb: 5 }}>
                            Invia messaggio alla sede
                        </Typography>
                    </HeadingStyle>
                    <Stack
                        component="form"
                        sx={{

                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-multiline-static"
                            label="Testo del messaggio"
                            multiline
                            rows={4}
                            onChange={(e)=>{setTesto(e.target.value.substring(0,3000))}}
                        />
                        {reason != '' ? <Alert severity='error'>{reason}</Alert> : <></>}
                        {success != '' ? <Alert severity='success'>{success}</Alert> : <></>}
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<ArrowBackIosIcon />} disabled={loading} style={{ width: "40%" }} color="secondary" onClick={() => { navigate('/home') }}>
                                Annulla
                            </Button>
                            <Button variant="contained" startIcon={<SendIcon />} disabled={loading || (testo.trim().length < 3)} style={{ width: "60%" }} onClick={handleInvia} >
                                Invia
                            </Button>
                        </Stack>
                        <Fade
                            in={loading}
                            
                            unmountOnExit
                        >
                            <LinearProgress color="secondary" />
                        </Fade>
                    </Stack>
                </ContentStyle>
            </Container>
        </RootStyle></>
}