import { useEffect, useState } from "react";

export const DateTime1 = () =>{
    var [date,setDate] = useState(new Date());
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    },[date]);

    return(
        <div>
            <p>Giorno : {date.toLocaleDateString()} Ora: {date.toLocaleTimeString()}</p>

        </div>
    )
}