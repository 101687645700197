import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { Logo } from "./components/Logo";
import { PrivateRoute } from "./components/PrivateRoute";
import { routeCodes } from "./components/Routes";
import { HomePage } from "./pages/Home";
import { PaginaMessaggio } from "./pages/InvioMessaggio";
import { LoginPage } from "./pages/Login";
import { SettingsPage } from "./pages/Settings";

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
     // default: 'black',
    },
  }
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Logo style={{position:"absolute",left:"16px",top:"16px"}} /> 
      <Routes>
        <Route
          path={routeCodes.BASE}
          element={
            <PrivateRoute>
              <LoginPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routeCodes.MESSAGE}
          element={
            <PrivateRoute>
              <PaginaMessaggio />
            </PrivateRoute>
          }
        />
        <Route
          path={routeCodes.HOME}
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routeCodes.SETTINGS}
          element={
            <PrivateRoute><SettingsPage /></PrivateRoute>
          }
        />
        <Route path={routeCodes.LOGIN} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routeCodes.BASE} />} />
      </Routes>
    </ThemeProvider>

  );
}