import {  Card, CardContent, Typography } from "@mui/material"

export const TimbraturaEntrataSospesa = (props) => {
    function format(d){
        let dd = new Date(d);
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return dd.toLocaleDateString("it-IT", options) +' '+dd.toLocaleTimeString("it-IT").substring(0,5);
    }
    return <>
        <Card variant="outlined">
            <CardContent variant="outlined">
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Timbratura ingresso precedente
                </Typography>
                <Typography variant="h5" component="div">
                    {format(props.data.data_entrata)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Note
                </Typography>
                <Typography variant="body2">
                    {props.data.note}
                </Typography>
            </CardContent>
        </Card>
    </>
}